var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "tw-h-[60px] tw-flex tw-justify-between tw-items-center tw-px-4 d-xl-none",
        },
        [
          _c(
            "b-toast",
            {
              attrs: {
                solid: "",
                toaster: "b-toaster-top-center",
                id: "cannedReplyToast",
                variant: "success",
                title: "Reply added!",
                "no-hover-pause": "",
              },
            },
            [_vm._v("Your reply has been succesfully added!")]
          ),
          _vm.conversationpatient.id != ""
            ? _c(
                "div",
                {
                  staticClass:
                    "tw-flex-initial tw-flex tw-items-center tw-space-x-2",
                },
                [
                  _vm.showInformation == true
                    ? [
                        _c("img", {
                          attrs: {
                            width: "25px",
                            src: require("@/assets/images/back_arrow.svg"),
                          },
                          on: {
                            click: function ($event) {
                              _vm.showInformation = false
                            },
                          },
                        }),
                      ]
                    : [
                        _c("img", {
                          attrs: {
                            width: "25px",
                            src: require("@/assets/images/back_arrow.svg"),
                          },
                          on: {
                            click: function ($event) {
                              _vm.conversationpatient.id = ""
                            },
                          },
                        }),
                      ],
                  _vm.isAdminView
                    ? _c("span", [
                        _vm._v("Admin for " + _vm._s(_vm.patientName)),
                      ])
                    : _c(
                        "div",
                        { staticClass: "tw-flex tw-items-center tw-space-x-2" },
                        [
                          _c("b-img", {
                            attrs: {
                              src: _vm.patientImg,
                              rounded: "circle",
                              width: "25px",
                              height: "25px",
                            },
                          }),
                          _c("span", [_vm._v(_vm._s(_vm.patientName))]),
                        ],
                        1
                      ),
                ],
                2
              )
            : _vm._e(),
          _vm.conversationpatient.id != ""
            ? [
                _c(
                  "div",
                  { staticClass: "tw-flex-initial" },
                  [
                    _vm.isMarkMessagesEnabled && !_vm.isAdminView
                      ? _c(
                          "b-button",
                          {
                            staticClass: "text-danger",
                            attrs: { pill: "", variant: "link", size: "sm" },
                            on: { click: _vm.onCancelMarkMessages },
                          },
                          [
                            _c("b-icon", {
                              attrs: {
                                "font-scale": "1",
                                icon: "x-circle-fill",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isAdminView
                      ? _c(
                          "b-dropdown",
                          {
                            attrs: {
                              id: "forward-dropdown",
                              right: "",
                              split: "",
                              variant: "primary",
                              "split-variant":
                                _vm.forwardRequestCounter === 1
                                  ? "primary"
                                  : "outline-primary",
                              text: "Forward",
                              size: "sm",
                            },
                            on: { click: _vm.onForwardRequest },
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.$bvModal.show(
                                      "viewForwardMessage"
                                    )
                                  },
                                },
                              },
                              [_vm._v(" View Forward Requests ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-dropdown",
                      {
                        attrs: {
                          variant: "link",
                          "toggle-class": "text-decoration-none",
                          "menu-class": "custom-dropdown-menu",
                          "no-caret": "",
                          right: "",
                          size: "sm",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  _c("b-icon", {
                                    attrs: {
                                      "font-scale": "1",
                                      icon: "three-dots-vertical",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1959241096
                        ),
                      },
                      [
                        !_vm.isAdminView
                          ? _c(
                              "b-dropdown-item",
                              {
                                staticClass: "tw-uppercase",
                                attrs: { "link-class": "tw-text-primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.isAdminView = true
                                  },
                                },
                              },
                              [_vm._v(" View Admin Messages ")]
                            )
                          : _c(
                              "b-dropdown-item",
                              {
                                staticClass: "tw-uppercase",
                                attrs: { "link-class": "tw-text-primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.isAdminView = false
                                  },
                                },
                              },
                              [_vm._v(" View Patient Messages ")]
                            ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "tw-uppercase",
                            attrs: { "link-class": "tw-text-primary" },
                          },
                          [_vm._v(" Intake History ")]
                        ),
                      ],
                      1
                    ),
                    _c("b-icon", {
                      attrs: {
                        icon: "file-earmark-person",
                        variant: "primary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showInformation = true
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "h3",
                  { staticClass: "text-primary text-center mb-0 tw-flex-1" },
                  [_vm._v("Messages")]
                ),
              ],
        ],
        2
      ),
      _c(
        "ul",
        {
          staticClass:
            "mobile-chat tw-relative tw-flex tw-flex-col tw-w-full tw-list-none tw-p-0 tw-mb-0 xl:tw-border xl:tw-border-solid xl:tw-border-[#E0E0E0] xl:tw-border-opacity-[0.5] d-xl-none",
        },
        [
          _c(
            "li",
            {
              ref: "mobileConversationListRef",
              staticClass:
                "tw-chat-list tw-relative tw-h-[calc(100vh-140px)] xl:tw-h-[calc(100vh-320px)] tw-overflow-auto",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "tw-chat-list-header tw-flex tw-items-center tw-border-0 tw-border-b tw-border-t tw-border-[#E0E0E0] tw-border-opacity-[0.5] tw-border-solid tw-sticky tw-top-0 tw-z-[10] xl:tw-border-t-0",
                },
                [
                  _c("div", { staticClass: "input-group input-group-flush" }, [
                    _vm._m(0),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter,
                          expression: "filter",
                        },
                      ],
                      staticClass:
                        "form-control list-search !tw-h-[60px] !tw-pl-0",
                      attrs: { type: "search", placeholder: "Search message" },
                      domProps: { value: _vm.filter },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.filter = $event.target.value
                          },
                          _vm.onConversationSearch,
                        ],
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "tw-flex tw-flex-1 tw-justify-center tw-items-center",
                    },
                    [
                      _vm.$store.state.message.currentPage > 1
                        ? _c(
                            "span",
                            {
                              staticClass: "tw--mt-1 tw-cursor-pointer",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.previousConversationList.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                  },
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "none",
                                    viewBox: "0 0 24 24",
                                    stroke: "#ff7a37",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                      "stroke-width": "2",
                                      d: "M15 19l-7-7 7-7",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$store.state.message.currentPage)),
                      ]),
                      !(
                        _vm.$store.state.message.currentPage ===
                        _vm.$store.state.message.pageCount
                      )
                        ? _c(
                            "span",
                            {
                              staticClass: "tw--mt-1 tw-cursor-pointer",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.nextConversationList.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                  },
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "none",
                                    viewBox: "0 0 24 24",
                                    stroke: "#ff7a37",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                      "stroke-width": "2",
                                      d: "M9 5l7 7-7 7",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "tw-chat-list-content tw-w-full" },
                [
                  _vm._l(_vm.conversationlist, function (conv, i) {
                    return _c(
                      "div",
                      {
                        key: `${conv.id}-${i}`,
                        staticClass: "tw-flex tw-items-center tw-px-6 tw-py-4",
                        class: [
                          _vm.conversation.id == conv.id
                            ? "patient-selected"
                            : "",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.setConversationId(conv)
                          },
                        },
                      },
                      [
                        _c("b-avatar", {
                          staticClass: "tw-flex-none",
                          attrs: {
                            variant: "tertiary",
                            src: Array.isArray(conv.profileUrl)
                              ? conv.profileUrl[0]
                              : conv.profileUrl,
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "tw-flex tw-flex-col tw-justify-center tw-flex-1 tw-min-w-0 tw-pl-4",
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticClass: "tw-mt-0 tw-truncate",
                                class: conv.lastmessage ? "tw-mb-1" : "tw-mb-0",
                              },
                              [_vm._v(" " + _vm._s(conv.name) + " ")]
                            ),
                            conv.lastmessage
                              ? _c("p", {
                                  directives: [
                                    {
                                      name: "html-safe",
                                      rawName: "v-html-safe",
                                      value: conv.lastmessage,
                                      expression: "conv.lastmessage",
                                    },
                                  ],
                                  staticClass: "tw-mb-0 tw-truncate",
                                })
                              : _vm._e(),
                          ]
                        ),
                        conv.needspatientreply
                          ? _c("b-badge", {
                              staticStyle: {
                                padding: "5px",
                                "border-radius": "9999px",
                                display: "inline-block",
                              },
                              attrs: { variant: "secondary" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _vm.$route.query.patientid
                    ? _c("div", { staticClass: "text-center" }, [
                        _c(
                          "a",
                          {
                            staticClass: "py-3 d-inline-block",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onLoadAllConversations.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v(" Load all conversations ")]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
          _vm.conversationpatient.id != ""
            ? _c(
                "li",
                {
                  staticClass:
                    "tw-chat-box tw-absolute tw-inset-0 tw-z-20 tw-bg-[#1a1a1a]",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-chat-body tw-flex tw-overflow-y-auto tw-items-start tw-flex-col-reverse",
                      style: { height: _vm.mHeight - _vm.mobileHeight + "px" },
                      on: {
                        "&scroll": function ($event) {
                          return _vm.messageScroll.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "chat-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "chat-inner",
                            class: { "mb-6": _vm.$_orientation == "landscape" },
                          },
                          _vm._l(_vm.messages, function (message, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "tw-items-center px-4",
                                class: {
                                  "tw-flex": _vm.isMarkMessagesEnabled,
                                  "tw-justify-between":
                                    message.userid == _vm.userid,
                                },
                              },
                              [
                                _vm.isMarkMessagesEnabled
                                  ? _c(
                                      "div",
                                      {
                                        key: "checkbox-" + i,
                                        staticClass: "tw-mr-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "custom-control custom-checkbox",
                                          },
                                          [
                                            _c("input", {
                                              staticClass:
                                                "forward-checkbox custom-control-input",
                                              attrs: {
                                                type: "checkbox",
                                                id: "checkbox-" + i,
                                                name: "checkbox-" + i,
                                              },
                                              domProps: {
                                                checked: _vm.isChecked(message),
                                              },
                                              on: {
                                                change: (e) =>
                                                  _vm.onForwardCheckboxChange(
                                                    e,
                                                    message
                                                  ),
                                              },
                                            }),
                                            _c("label", {
                                              staticClass:
                                                "custom-control-label",
                                              attrs: { for: "checkbox-" + i },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                message.type === "prescription_followup"
                                  ? _c(
                                      "div",
                                      { key: i },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            staticClass:
                                              "followup-update text-center mt-3 p-3 mb-3",
                                          },
                                          [
                                            message.actionstatus === "PENDING"
                                              ? _c(
                                                  "p",
                                                  { staticClass: "mb-0" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-block mb-1",
                                                        staticStyle: {
                                                          "text-align":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Follow-up questions has been sent to the patient "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "d-block text-left time-stamp-followup",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Time sent: " +
                                                            _vm._s(
                                                              _vm.moment
                                                                .unix(
                                                                  message.sentat
                                                                )
                                                                .format(
                                                                  "MMM DD, YYYY hh:mm A"
                                                                )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            message.actionstatus === "ANSWERED"
                                              ? _c(
                                                  "p",
                                                  { staticClass: "mb-0" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-block mb-1",
                                                        staticStyle: {
                                                          "text-align":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Follow-up questions has been answered "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "d-block text-left time-stamp-followup mb-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Time sent: " +
                                                            _vm._s(
                                                              _vm.moment
                                                                .unix(
                                                                  message.sentat
                                                                )
                                                                .format(
                                                                  "MMM DD, YYYY hh:mm A"
                                                                )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "d-block text-left time-stamp-followup",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Time answered: " +
                                                            _vm._s(
                                                              _vm.moment
                                                                .unix(
                                                                  message
                                                                    .custommessage
                                                                    .message
                                                                    .timestampanswered
                                                                )
                                                                .format(
                                                                  "MMM DD, YYYY hh:mm A"
                                                                )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : message.type === "reassignment_transfer"
                                  ? _c(
                                      "div",
                                      { key: `readmission_transfer-${i}` },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            staticClass:
                                              "followup-update mt-3 p-3 mb-3",
                                          },
                                          [
                                            _c("p", { staticClass: "mb-0" }, [
                                              _c(
                                                "span",
                                                { staticClass: "d-block mb-1" },
                                                [
                                                  _vm._v(
                                                    " Patient has been reassigned to "
                                                  ),
                                                  message.custommessage.message
                                                    .doctoridto === _vm.userid
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " you from Dr. " +
                                                            _vm._s(
                                                              message
                                                                .custommessage
                                                                .message
                                                                .doctorfrom
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          " Dr. " +
                                                            _vm._s(
                                                              message
                                                                .custommessage
                                                                .message
                                                                .doctorto
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                  message.custommessage.message
                                                    .reassignmentstatus !==
                                                  "RETURNED"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            message
                                                              .custommessage
                                                              .message
                                                              .assignmenttype ===
                                                              "TEMPORARY"
                                                              ? "temporarily"
                                                              : "permanently"
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                              message.custommessage.message
                                                .assignmenttype ===
                                                "TEMPORARY" &&
                                              message.custommessage.message
                                                .reassignmentstatus !==
                                                "RETURNED"
                                                ? _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "d-block text-left time-stamp-followup",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.moment
                                                              .unix(
                                                                message
                                                                  .custommessage
                                                                  .message
                                                                  .startdatestamp
                                                              )
                                                              .format(
                                                                "MMM DD, YYYY hh:mm A"
                                                              )
                                                          ) +
                                                          " to " +
                                                          _vm._s(
                                                            _vm.moment
                                                              .unix(
                                                                message
                                                                  .custommessage
                                                                  .message
                                                                  .enddatestamp
                                                              )
                                                              .format(
                                                                "MMM DD, YYYY hh:mm A"
                                                              )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : message.type === "prescription_confirmation"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            staticClass:
                                              "treatment-update text-center mt-3 mb-3",
                                            staticStyle: {
                                              "background-color": "#e9f5f3",
                                            },
                                          },
                                          [
                                            _c("h2", { staticClass: "mb-2" }, [
                                              _vm._v(
                                                " Updated Treatment: " +
                                                  _vm._s(
                                                    message.custommessage
                                                      .message.productname
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("p", { staticClass: "mb-0" }, [
                                              _vm._v(
                                                " The price of " +
                                                  _vm._s(
                                                    message.custommessage
                                                      .message.productname
                                                  ) +
                                                  " treatment has changed from $" +
                                                  _vm._s(
                                                    message.custommessage
                                                      .message.pricefrom
                                                  ) +
                                                  " to $" +
                                                  _vm._s(
                                                    message.custommessage
                                                      .message.priceto
                                                  ) +
                                                  ". "
                                              ),
                                            ]),
                                            message.actionstatus === "PENDING"
                                              ? _c(
                                                  "p",
                                                  { staticClass: "mb-0" },
                                                  [
                                                    _vm._v(
                                                      " Awaiting confirmation from patient "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            message.actionstatus === "ACCEPTED"
                                              ? _c(
                                                  "p",
                                                  { staticClass: "mb-0" },
                                                  [
                                                    _vm._v(
                                                      " Prescription has been accepted by patient "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            message.actionstatus === "DECLINED"
                                              ? _c(
                                                  "p",
                                                  { staticClass: "mb-0" },
                                                  [
                                                    _vm._v(
                                                      " Prescription has been declined by patient "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        class: [
                                          message.userid != _vm.userid
                                            ? "patient"
                                            : "doctor",
                                          "chat-bubble",
                                        ],
                                      },
                                      [
                                        _c("div", { staticClass: "chat-img" }, [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.getMemberProfileUrl(
                                                message
                                              ),
                                              alt: "",
                                            },
                                          }),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              message.userid != _vm.userid
                                                ? "patient"
                                                : "doctor",
                                              "chat",
                                              _vm.isAdminView &&
                                              message.userid != _vm.userid
                                                ? "admin"
                                                : "",
                                            ],
                                          },
                                          [
                                            message.attachment &&
                                            message.attachment.length > 0
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    message.attachment,
                                                    function (
                                                      chatAttachment,
                                                      i
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: i,
                                                          staticStyle: {
                                                            "background-color":
                                                              "transparent",
                                                          },
                                                        },
                                                        [
                                                          chatAttachment.type.includes(
                                                            "image"
                                                          ) &&
                                                          ![
                                                            "tiff",
                                                            "heic",
                                                            "svg",
                                                          ].some((type) =>
                                                            chatAttachment.type.includes(
                                                              type
                                                            )
                                                          )
                                                            ? _c(
                                                                "b-row",
                                                                {
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _c("b-col", [
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        attrs: {
                                                                          href: chatAttachment.url,
                                                                          variant:
                                                                            "link",
                                                                          download:
                                                                            "",
                                                                          target:
                                                                            "_blank",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                thumbnail:
                                                                                  "",
                                                                                fluid:
                                                                                  "",
                                                                                src: chatAttachment.url,
                                                                                width:
                                                                                  "250px",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                "b-row",
                                                                {
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href: chatAttachment.url,
                                                                        variant:
                                                                          "link",
                                                                        download:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              icon: "arrow-down-circle-fill",
                                                                              variant:
                                                                                "primary",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            chatAttachment.filename
                                                                          )
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            message.message !== "null"
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(message.message) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _c("br"),
                                            _c(
                                              "small",
                                              {
                                                staticClass:
                                                  "tw-flex tw-flex-col",
                                                class: [
                                                  message.userid != _vm.userid
                                                    ? "patient"
                                                    : "doctor",
                                                  "time-stamp",
                                                ],
                                              },
                                              [
                                                message.userid != _vm.userid &&
                                                _vm.isAdminView
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "mb-1" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getAdminName(
                                                                message.userid
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.moment
                                                          .unix(message.sentat)
                                                          .format(
                                                            "MMM DD, YYYY hh:mm A"
                                                          )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                  _vm.conversationpatient.needsPatientReply && !_vm.isAdminView
                    ? _c(
                        "div",
                        { staticClass: "tw-text-center text-secondary" },
                        [_vm._v(" Needs patient reply ")]
                      )
                    : _vm._e(),
                  !_vm.currentDoctor.id
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "resize",
                              rawName: "v-resize",
                              value: _vm.observeMobileChat,
                              expression: "observeMobileChat",
                            },
                          ],
                          staticClass:
                            "tw-chat-footer tw-border-0 tw-border-t tw-border-[#E0E0E0] tw-border-opacity-[0.5] tw-border-solid",
                        },
                        [
                          _vm.preUpload
                            ? _c(
                                "div",
                                { staticClass: "pre-upload" },
                                _vm._l(
                                  _vm.attachtments,
                                  function (attachtment, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "img-holder" },
                                      [
                                        _c("b-icon", {
                                          staticClass: "close-btn",
                                          attrs: { icon: "x-circle-fill" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeFile(index)
                                            },
                                          },
                                        }),
                                        !attachtment.type.includes("image") ||
                                        attachtment.type.includes("heic")
                                          ? _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  width: "32",
                                                  height: "32",
                                                  viewBox: "0 0 24 24",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    fill: "currentColor",
                                                    d: "M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6zm7 6V3.5L18.5 9H14c-.55 0-1-.45-1-1z",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c("img", {
                                              attrs: {
                                                src: attachtment.url,
                                                alt: attachtment.name,
                                              },
                                            }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: {
                                "tw-flex": _vm.$_orientation == "landscape",
                                "lg:tw-block": _vm.$_orientation == "landscape",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "tw-flex tw-items-center",
                                  class: {
                                    "tw-grow": _vm.$_orientation == "landscape",
                                  },
                                },
                                [
                                  _c("ComposerEditor", {
                                    ref: "composer",
                                    staticClass: "tw-w-full",
                                    attrs: {
                                      "hide-toolbar": "",
                                      "hide-send-button": "",
                                    },
                                    model: {
                                      value: _vm.newMessage,
                                      callback: function ($$v) {
                                        _vm.newMessage = $$v
                                      },
                                      expression: "newMessage",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "tw-flex-initial" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "link" },
                                          on: { click: _vm.openUpload },
                                        },
                                        [
                                          _c("IconPaperclip", {
                                            staticClass:
                                              "tw-w-5 tw-h-5 tw-text-gray-800",
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("input", {
                                        ref: "fileInput",
                                        staticStyle: { display: "none" },
                                        attrs: { type: "file", multiple: "" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleFilesUpload()
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "tw-flex-initial" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "link" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showCannedReplyModal = true
                                            },
                                          },
                                        },
                                        [
                                          _c("IconCannedReply", {
                                            staticClass:
                                              "tw-w-5 tw-h-5 tw-text-gray-800",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "tw-flex tw-p-2 tw-space-x-2" },
                                [
                                  !_vm.isAdminView
                                    ? _c(
                                        "b-button",
                                        {
                                          staticStyle: { "line-height": "0" },
                                          attrs: {
                                            variant: "danger",
                                            pill: "",
                                            block: "",
                                            disabled: _vm.isMessageBodyEmpty,
                                          },
                                          on: {
                                            click: _vm.sendMessageNeedsReply,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "tw-text-xs" },
                                            [_vm._v(" Send Message ")]
                                          ),
                                          _c("br"),
                                          _c(
                                            "span",
                                            { staticClass: "tw-text-xs" },
                                            [_vm._v(" Needs Reply ")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-button",
                                    {
                                      staticStyle: { "line-height": "0" },
                                      attrs: {
                                        variant: "primary",
                                        pill: "",
                                        block: "",
                                        disabled: _vm.isMessageBodyEmpty,
                                      },
                                      on: { click: () => _vm.sendMessage() },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "tw-text-xs" },
                                        [_vm._v(" Send Message ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.showInformation
            ? _c(
                "li",
                {
                  staticClass:
                    "tw-patient-information tw-absolute tw-inset-0 tw-z-30 tw-overflow-auto",
                },
                [
                  _vm.conversationpatient.id != ""
                    ? _c(
                        "b-card",
                        {
                          staticClass: "patient-info mb-0",
                          scopedSlots: _vm._u(
                            [
                              !_vm.currentDoctor.id
                                ? {
                                    key: "footer",
                                    fn: function () {
                                      return [
                                        !_vm.conversationpatient.isBanned
                                          ? _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  pill: "",
                                                  variant: "secondary",
                                                  block: "",
                                                  to: `/prescriptions/order/${_vm.conversationpatient.userId}`,
                                                },
                                              },
                                              [_vm._v(" Create a Plan ")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              pill: "",
                                              variant: "outline-secondary",
                                              to: `/patients/profile/${_vm.conversationpatient.userId}`,
                                              block: "",
                                            },
                                          },
                                          [_vm._v(" View Patient Profile ")]
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName:
                                                  "v-b-modal.viewDosespotFromMessage",
                                                modifiers: {
                                                  viewDosespotFromMessage: true,
                                                },
                                              },
                                            ],
                                            attrs: {
                                              pill: "",
                                              variant: "outline-secondary",
                                              block: "",
                                            },
                                          },
                                          [_vm._v(" Open Dosespot ")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "mb-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c("b-avatar", {
                                            attrs: {
                                              size: "85px",
                                              src: _vm.conversationpatient
                                                .profileUrl,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("b-col", [
                                        _c(
                                          "h3",
                                          { staticClass: "bold text-black" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.conversationpatient
                                                    .firstName
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.conversationpatient
                                                    .lastName
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center justify-content-center",
                                                attrs: {
                                                  pill: "",
                                                  size: "sm",
                                                  variant: "outline-dark",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showNotesModal = true
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", {
                                                  attrs: {
                                                    name: "note",
                                                    variant: "",
                                                    size: "20",
                                                    color: "currentColor",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-inline-block ml-2",
                                                  },
                                                  [_vm._v(" Notes ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            !_vm.currentDoctor.id
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center justify-content-center",
                                                    attrs: {
                                                      pill: "",
                                                      size: "sm",
                                                      variant: "outline-dark",
                                                      block: "",
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", {
                                                      attrs: {
                                                        name: "reminder",
                                                        color: "currentColor",
                                                        variant: "",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v("Set Reminder"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "text-muted mb-2" },
                                            [_c("small", [_vm._v("Condition")])]
                                          ),
                                          _c(
                                            "h4",
                                            { staticClass: "text-black" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.conversationpatient
                                                    .symptoms
                                                )
                                              ),
                                            ]
                                          ),
                                          !_vm.conversationpatient.symptoms
                                            ? _c(
                                                "h4",
                                                { staticClass: "text-muted" },
                                                [_vm._v(" No records! ")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "text-muted mb-2" },
                                            [
                                              _c("small", [
                                                _vm._v("Other Medications"),
                                              ]),
                                            ]
                                          ),
                                          _vm.conversationpatient
                                            .otherMedication
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.conversationpatient
                                                    .otherMedication,
                                                  function (medication, i) {
                                                    return _c(
                                                      "h4",
                                                      { key: i },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(medication) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c(
                                                "h4",
                                                { staticClass: "text-muted" },
                                                [_vm._v("No records!")]
                                              ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("h4", { staticClass: "mb-0 bold" }, [
                            _vm._v("Treatment Plans"),
                          ]),
                          _c(
                            "b-list-group",
                            { attrs: { flush: "" } },
                            [
                              _vm._l(
                                _vm.conversationpatient.treatmentPlanDetail,
                                function (treat, a) {
                                  return _c(
                                    "b-list-group-item",
                                    { key: a },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "mb-2",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "b-row",
                                                [
                                                  _c("b-col", [
                                                    _c("small", [
                                                      _vm._v(
                                                        _vm._s(
                                                          treat.description
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "auto" } },
                                                    [
                                                      _c("small", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.moment
                                                              .unix(treat.date)
                                                              .format(
                                                                "MMMM DD, YYYY"
                                                              )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-row",
                                                [
                                                  _c(
                                                    "b-col",
                                                    [
                                                      _c("b-badge", {
                                                        class: [
                                                          "mr-2",
                                                          treat.status ==
                                                          "PAUSED"
                                                            ? "badge-warning"
                                                            : treat.status ==
                                                              "CANCELLED"
                                                            ? "badge-danger"
                                                            : treat.status ==
                                                              "ACCEPTED"
                                                            ? "badge-success"
                                                            : treat.status ==
                                                              "PRESCRIBED"
                                                            ? "badge-success"
                                                            : "",
                                                        ],
                                                        staticStyle: {
                                                          padding: "5px",
                                                          "border-radius":
                                                            "9999px",
                                                          display:
                                                            "inline-block",
                                                        },
                                                      }),
                                                      _c("small", [
                                                        _vm._v(
                                                          _vm._s(treat.status)
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm.conversationpatient.treatmentPlanDetail !=
                                undefined &&
                              _vm.conversationpatient.treatmentPlanDetail
                                .length == 0
                                ? _c("b-list-group-item", [
                                    _c(
                                      "p",
                                      { staticClass: "text-muted mb-0" },
                                      [_vm._v("No Medication")]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c("div", { ref: "bottomOfPage", attrs: { id: "bottomOfPage" } }),
        ]
      ),
      _c(
        "b-row",
        { staticClass: "content d-none d-xl-flex mx-4" },
        [
          _c(
            "b-col",
            { staticClass: "pr-0", attrs: { cols: "12", lg: "3" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "chat-patient-list",
                  attrs: { "no-body": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "input-group input-group-flush",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group-prepend" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "input-group-text" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fe fe-search text-primary",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.filter,
                                          expression: "filter",
                                        },
                                      ],
                                      staticClass: "form-control list-search",
                                      attrs: {
                                        type: "search",
                                        placeholder: "Search",
                                      },
                                      domProps: { value: _vm.filter },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.filter = $event.target.value
                                          },
                                          _vm.onConversationSearch,
                                        ],
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "px-2 py-2 d-flex justify-content-center align-items-center",
                                  },
                                  [
                                    _vm.$store.state.message.currentPage > 1
                                      ? _c(
                                          "a",
                                          {
                                            attrs: { href: "" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.previousConversationList.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticStyle: {
                                                  width: "20px",
                                                  height: "20px",
                                                },
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  fill: "none",
                                                  viewBox: "0 0 24 24",
                                                  stroke: "currentColor",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "stroke-width": "2",
                                                    d: "M15 19l-7-7 7-7",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "small",
                                      {
                                        staticClass: "text-muted px-1",
                                        staticStyle: { "line-height": "0" },
                                      },
                                      [
                                        _vm._v(
                                          " Page " +
                                            _vm._s(
                                              _vm.$store.state.message
                                                .currentPage
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm.$store.state.message.pageCount >
                                    _vm.$store.state.message.currentPage
                                      ? _c(
                                          "a",
                                          {
                                            attrs: { href: "" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.nextConversationList.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticStyle: {
                                                  width: "20px",
                                                  height: "20px",
                                                },
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  fill: "none",
                                                  viewBox: "0 0 24 24",
                                                  stroke: "currentColor",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "stroke-width": "2",
                                                    d: "M9 5l7 7-7 7",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "ul",
                    {
                      ref: "conversationListRef",
                      staticClass: "list-unstyled",
                    },
                    [
                      _vm._l(_vm.conversationlist, function (conv, i) {
                        return _c(
                          "li",
                          {
                            key: `${conv.id}-${i}`,
                            class: [
                              _vm.conversation.id == conv.id ? "selected" : "",
                              "patient-list",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.setConversationId(conv)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "list-img" },
                              [
                                _c("b-avatar", {
                                  attrs: {
                                    variant: "tertiary",
                                    src: Array.isArray(conv.profileUrl)
                                      ? conv.profileUrl[0]
                                      : conv.profileUrl,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "list-content" },
                              [
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "mt-0 mb-1 text-capitalize",
                                    },
                                    [_vm._v(_vm._s(conv.name))]
                                  ),
                                  _c(
                                    "p",
                                    { staticClass: "mb-0 text-truncate" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.trimHtmlTags(conv.lastmessage)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                              ],
                              2
                            ),
                            conv.needspatientreply
                              ? _c("b-badge", {
                                  staticStyle: {
                                    padding: "5px",
                                    "border-radius": "9999px",
                                    display: "inline-block",
                                  },
                                  attrs: { variant: "secondary" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _vm.$route.query.patientid
                        ? _c("li", { staticClass: "text-center" }, [
                            _c(
                              "a",
                              {
                                staticClass: "py-3 d-inline-block",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.onLoadAllConversations.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Load all conversations ")]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "d-none d-sm-none d-lg-block",
              attrs: {
                cols: "12",
                lg: _vm.conversationpatient.id == "" ? "9" : "6",
              },
            },
            [
              _vm.conversationpatient.id != ""
                ? [
                    _c(
                      "b-card",
                      {
                        staticClass: "mb-0 chats",
                        staticStyle: {
                          "border-left": "0",
                          "border-right": "0",
                          "border-bottom": "0",
                          height: "calc(100vh - 200px)",
                        },
                        attrs: { "no-body": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-flex tw-justify-between tw-items-center",
                                    },
                                    [
                                      _c("h3", { staticClass: "mb-0" }, [
                                        _vm.isAdminView
                                          ? _c(
                                              "span",
                                              { staticClass: "tw-text-lg" },
                                              [_vm._v("Admin for ")]
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.conversationpatient.firstName
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.conversationpatient.lastName
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          !_vm.isAdminView
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "text-primary tw-underline tw-mr-4",
                                                  attrs: {
                                                    pill: "",
                                                    variant: "link",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.isAdminView = true
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Admin ")]
                                              )
                                            : _vm._e(),
                                          _vm.isAdminView
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "text-primary tw-underline tw-mr-4",
                                                  attrs: {
                                                    pill: "",
                                                    variant: "link",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.isAdminView = false
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Patient ")]
                                              )
                                            : _vm._e(),
                                          _vm.isMarkMessagesEnabled &&
                                          !_vm.isAdminView
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass: "text-secondary",
                                                  attrs: {
                                                    pill: "",
                                                    variant: "link",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.onCancelMarkMessages,
                                                  },
                                                },
                                                [_vm._v(" Cancel ")]
                                              )
                                            : _vm._e(),
                                          !_vm.isAdminView
                                            ? _c(
                                                "b-dropdown",
                                                {
                                                  attrs: {
                                                    id: "forward-dropdown",
                                                    right: "",
                                                    split: "",
                                                    variant: "primary",
                                                    "split-variant":
                                                      _vm.forwardRequestCounter ===
                                                      1
                                                        ? "primary"
                                                        : "outline-primary",
                                                    text: "Forward",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: _vm.onForwardRequest,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.$bvModal.show(
                                                            "viewForwardMessage"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " View Forward Requests "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1887869973
                        ),
                      },
                      [
                        _c(
                          "b-card-body",
                          {
                            style: {
                              height: _vm.currentDoctor.id
                                ? "calc(100vh - 260px)"
                                : _vm.height - _vm.messageHeight - 75 + "px",
                            },
                            attrs: { "body-class": "card-chat-body pb-2" },
                            on: {
                              "&scroll": function ($event) {
                                return _vm.messageScroll.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                class: ["chat-box"],
                                attrs: { id: "chat-box" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "chat-inner" },
                                  _vm._l(_vm.messages, function (message, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: "parent-" + i,
                                        staticClass: "tw-flex tw-items-center",
                                        class: {
                                          "selected-message":
                                            _vm.selectedMessages.some(
                                              (msg) => msg.id === message.id
                                            ),
                                        },
                                        staticStyle: { padding: "0 1.5rem" },
                                      },
                                      [
                                        _vm.isMarkMessagesEnabled
                                          ? _c(
                                              "div",
                                              {
                                                key: "checkbox-" + i,
                                                staticClass: "tw-mr-2",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "custom-control custom-checkbox",
                                                  },
                                                  [
                                                    _c("input", {
                                                      staticClass:
                                                        "forward-checkbox custom-control-input",
                                                      attrs: {
                                                        type: "checkbox",
                                                        id: "checkbox-" + i,
                                                        name: "checkbox-" + i,
                                                      },
                                                      domProps: {
                                                        checked:
                                                          _vm.isChecked(
                                                            message
                                                          ),
                                                      },
                                                      on: {
                                                        change: (e) =>
                                                          _vm.onForwardCheckboxChange(
                                                            e,
                                                            message
                                                          ),
                                                      },
                                                    }),
                                                    _c("label", {
                                                      staticClass:
                                                        "custom-control-label",
                                                      attrs: {
                                                        for: "checkbox-" + i,
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        message.type === "prescription_followup"
                                          ? _c(
                                              "div",
                                              { key: i },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    staticClass:
                                                      "followup-update text-center mt-3 p-3 mb-3",
                                                  },
                                                  [
                                                    message.actionstatus ===
                                                    "PENDING"
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass: "mb-0",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "d-block mb-1",
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Follow-up questions has been sent to the patient "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "d-block text-left time-stamp-followup",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Time sent: " +
                                                                    _vm._s(
                                                                      _vm.moment
                                                                        .unix(
                                                                          message.sentat
                                                                        )
                                                                        .format(
                                                                          "MMM DD, YYYY hh:mm A"
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    message.actionstatus ===
                                                    "ANSWERED"
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass: "mb-0",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "d-block mb-1",
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Follow-up questions has been answered "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "d-block text-left time-stamp-followup mb-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Time sent: " +
                                                                    _vm._s(
                                                                      _vm.moment
                                                                        .unix(
                                                                          message.sentat
                                                                        )
                                                                        .format(
                                                                          "MMM DD, YYYY hh:mm A"
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "d-block text-left time-stamp-followup",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Time answered: " +
                                                                    _vm._s(
                                                                      _vm.moment
                                                                        .unix(
                                                                          message
                                                                            .custommessage
                                                                            .message
                                                                            .timestampanswered
                                                                        )
                                                                        .format(
                                                                          "MMM DD, YYYY hh:mm A"
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : message.type ===
                                            "reassignment_transfer"
                                          ? _c(
                                              "div",
                                              {
                                                key: `reassignment_transfer-${i}`,
                                              },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    staticClass:
                                                      "followup-update mt-3 p-3 mb-3",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "d-block mb-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Patient has been reassigned to "
                                                            ),
                                                            message
                                                              .custommessage
                                                              .message
                                                              .doctoridto ===
                                                            _vm.userid
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " you from Dr. " +
                                                                      _vm._s(
                                                                        message
                                                                          .custommessage
                                                                          .message
                                                                          .doctorfrom
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    " Dr. " +
                                                                      _vm._s(
                                                                        message
                                                                          .custommessage
                                                                          .message
                                                                          .doctorto
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                            message
                                                              .custommessage
                                                              .message
                                                              .reassignmentstatus !==
                                                            "RETURNED"
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      message
                                                                        .custommessage
                                                                        .message
                                                                        .assignmenttype ===
                                                                        "TEMPORARY"
                                                                        ? "temporarily"
                                                                        : "permanently"
                                                                    )
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        message.custommessage
                                                          .message
                                                          .assignmenttype ===
                                                          "TEMPORARY" &&
                                                        message.custommessage
                                                          .message
                                                          .reassignmentstatus !==
                                                          "RETURNED"
                                                          ? _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "d-block text-left time-stamp-followup",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.moment
                                                                        .unix(
                                                                          message
                                                                            .custommessage
                                                                            .message
                                                                            .startdatestamp
                                                                        )
                                                                        .format(
                                                                          "MMM DD, YYYY hh:mm A"
                                                                        )
                                                                    ) +
                                                                    " to " +
                                                                    _vm._s(
                                                                      _vm.moment
                                                                        .unix(
                                                                          message
                                                                            .custommessage
                                                                            .message
                                                                            .enddatestamp
                                                                        )
                                                                        .format(
                                                                          "MMM DD, YYYY hh:mm A"
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : message.type ===
                                            "prescription_confirmation"
                                          ? _c(
                                              "div",
                                              {
                                                key: `prescription_confirmation-${i}`,
                                              },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    staticClass:
                                                      "treatment-update text-center mt-3 mb-3",
                                                    staticStyle: {
                                                      "background-color":
                                                        "#e9f5f3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "h2",
                                                      { staticClass: "mb-2" },
                                                      [
                                                        _vm._v(
                                                          " Updated Treatment: " +
                                                            _vm._s(
                                                              message
                                                                .custommessage
                                                                .message
                                                                .productname
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          " The price of " +
                                                            _vm._s(
                                                              message
                                                                .custommessage
                                                                .message
                                                                .productname
                                                            ) +
                                                            " treatment has changed from $" +
                                                            _vm._s(
                                                              message
                                                                .custommessage
                                                                .message
                                                                .pricefrom
                                                            ) +
                                                            " to $" +
                                                            _vm._s(
                                                              message
                                                                .custommessage
                                                                .message.priceto
                                                            ) +
                                                            ". "
                                                        ),
                                                      ]
                                                    ),
                                                    message.actionstatus ===
                                                    "PENDING"
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass: "mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Awaiting confirmation from patient "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    message.actionstatus ===
                                                    "ACCEPTED"
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass: "mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Prescription has been accepted by patient "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    message.actionstatus ===
                                                    "DECLINED"
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass: "mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Prescription has been declined by patient "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : message.type === "forward_message"
                                          ? _c(
                                              "div",
                                              {
                                                key: `forward_message-${i}`,
                                                staticClass: "tw-flex-1",
                                                class: [
                                                  message.userid != _vm.userid
                                                    ? "patient"
                                                    : "doctor",
                                                  "chat-bubble",
                                                ],
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "chat-img" },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.getMemberProfileUrl(
                                                          message
                                                        ),
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tw-relative doctor chat tw-ml-[10px]",
                                                    style:
                                                      message.custommessage
                                                        .forwardmessage
                                                        .messagestatus ===
                                                      "COMPLETED"
                                                        ? "background-color: #F5FEEF"
                                                        : "background-color: rgba(26, 106, 114, 0.05)",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "tw-text-right",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-secondary tw-cursor-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.$refs.viewForwardMessages.onView(
                                                                  message
                                                                    .custommessage
                                                                    .forwardmessage
                                                                    .id,
                                                                  message
                                                                    .custommessage
                                                                    .forwardmessage
                                                                    .conversationid
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v(" View ")]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      message.custommessage.forwardmessage.messagelist
                                                        .slice()
                                                        .reverse(),
                                                      function (chat) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: chat.id,
                                                            staticClass:
                                                              "tw-mb-2",
                                                          },
                                                          [
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-muted tw-text-[11px] tw-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Sent by " +
                                                                    _vm._s(
                                                                      `${chat.firstname} ${chat.lastname}`
                                                                    ) +
                                                                    " at " +
                                                                    _vm._s(
                                                                      _vm.moment
                                                                        .unix(
                                                                          chat.sentat
                                                                        )
                                                                        .format(
                                                                          "MMM DD, YYYY hh:mm A"
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "tw-px-4 tw-py-2 tw-bg-white tw-inline-block",
                                                                style:
                                                                  chat.usertype ===
                                                                  "PATIENT"
                                                                    ? "background-color: #FFECCA"
                                                                    : "",
                                                              },
                                                              [
                                                                chat.attachment &&
                                                                chat.attachment
                                                                  .length > 0
                                                                  ? _c(
                                                                      "div",
                                                                      _vm._l(
                                                                        chat.attachment,
                                                                        function (
                                                                          chatAttachment,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: i,
                                                                              staticStyle:
                                                                                {
                                                                                  "background-color":
                                                                                    "transparent",
                                                                                },
                                                                            },
                                                                            [
                                                                              chatAttachment.type !=
                                                                              "application/pdf"
                                                                                ? _c(
                                                                                    "b-row",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "no-gutters":
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-col",
                                                                                        [
                                                                                          _c(
                                                                                            "a",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  href: chatAttachment.url,
                                                                                                  variant:
                                                                                                    "link",
                                                                                                  download:
                                                                                                    "",
                                                                                                  target:
                                                                                                    "_blank",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "b-img",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      thumbnail:
                                                                                                        "",
                                                                                                      fluid:
                                                                                                        "",
                                                                                                      src: chatAttachment.url,
                                                                                                      width:
                                                                                                        "250px",
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _c(
                                                                                    "b-row",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "no-gutters":
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "a",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              href: chatAttachment.url,
                                                                                              variant:
                                                                                                "link",
                                                                                              download:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "b-icon",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  icon: "arrow-down-circle-fill",
                                                                                                  variant:
                                                                                                    "primary",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                chatAttachment.filename
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    )
                                                                  : _vm._e(),
                                                                _c("span", {
                                                                  directives: [
                                                                    {
                                                                      name: "html-safe",
                                                                      rawName:
                                                                        "v-html-safe",
                                                                      value:
                                                                        chat.message,
                                                                      expression:
                                                                        "chat.message",
                                                                    },
                                                                  ],
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    message.custommessage
                                                      .forwardmessage
                                                      .messagestatus ===
                                                    "COMPLETED"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "tw-text-center tw-mt-2 tw-text-sm tw-text-green-900",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  message
                                                                    .custommessage
                                                                    .forwardmessage
                                                                    .messagestatus
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                key: `chat-${i}`,
                                                staticClass: "tw-flex-1",
                                                class: [
                                                  message.userid != _vm.userid
                                                    ? "patient"
                                                    : "doctor",
                                                  "chat-bubble",
                                                ],
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "chat-img" },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.getMemberProfileUrl(
                                                          message
                                                        ),
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "tw-relative",
                                                    class: [
                                                      message.userid !=
                                                      _vm.userid
                                                        ? "patient"
                                                        : "doctor",
                                                      "chat",
                                                      _vm.isAdminView &&
                                                      message.userid !=
                                                        _vm.userid
                                                        ? "admin"
                                                        : "",
                                                    ],
                                                  },
                                                  [
                                                    message.attachment &&
                                                    message.attachment.length >
                                                      0
                                                      ? _c(
                                                          "div",
                                                          _vm._l(
                                                            message.attachment,
                                                            function (
                                                              chatAttachment,
                                                              i
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: i,
                                                                  staticStyle: {
                                                                    "background-color":
                                                                      "transparent",
                                                                  },
                                                                },
                                                                [
                                                                  chatAttachment.type.includes(
                                                                    "image"
                                                                  )
                                                                    ? _c(
                                                                        "b-row",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "no-gutters":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-col",
                                                                            [
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      href: chatAttachment.url,
                                                                                      variant:
                                                                                        "link",
                                                                                      download:
                                                                                        "",
                                                                                      target:
                                                                                        "_blank",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "b-img",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          thumbnail:
                                                                                            "",
                                                                                          fluid:
                                                                                            "",
                                                                                          src: chatAttachment.url,
                                                                                          width:
                                                                                            "250px",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _c(
                                                                        "b-row",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "no-gutters":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  href: chatAttachment.url,
                                                                                  variant:
                                                                                    "link",
                                                                                  download:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "arrow-down-circle-fill",
                                                                                      variant:
                                                                                        "primary",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    chatAttachment.filename
                                                                                  )
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                    message.message !== "null"
                                                      ? _c("span", {
                                                          directives: [
                                                            {
                                                              name: "html-safe",
                                                              rawName:
                                                                "v-html-safe",
                                                              value:
                                                                message.message,
                                                              expression:
                                                                "message.message",
                                                            },
                                                          ],
                                                        })
                                                      : _vm._e(),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "tw-absolute tw-left-0 tw-flex tw-flex-col tw-items-start tw-w-[150px]",
                                                        class: [
                                                          message.userid !=
                                                          _vm.userid
                                                            ? "patient"
                                                            : "doctor",
                                                          "time-stamp",
                                                        ],
                                                        style: {
                                                          bottom:
                                                            message.userid !=
                                                              _vm.userid &&
                                                            _vm.isAdminView
                                                              ? "-24px"
                                                              : "-14px",
                                                        },
                                                      },
                                                      [
                                                        message.userid !=
                                                          _vm.userid &&
                                                        _vm.isAdminView
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getAdminName(
                                                                      message.userid
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "tw-flex tw-gap-3",
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.moment
                                                                      .unix(
                                                                        message.sentat
                                                                      )
                                                                      .format(
                                                                        "MMM DD, YYYY hh:mm A"
                                                                      )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                            _c(
                                                              "ChatRoutedTooltip",
                                                              {
                                                                attrs: {
                                                                  message:
                                                                    message,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ]
                        ),
                        !_vm.currentDoctor.id
                          ? _c(
                              "b-card-footer",
                              {
                                directives: [
                                  {
                                    name: "resize",
                                    rawName: "v-resize",
                                    value: _vm.observeTextarea,
                                    expression: "observeTextarea",
                                  },
                                ],
                                attrs: {
                                  "footer-class": `custom-footer tw-relative ${
                                    _vm.isAdminView ? "tw-mt-8" : ""
                                  }`,
                                },
                              },
                              [
                                _vm.isAdminView
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger tw-top-[-25px]",
                                        class: {
                                          "tw-absolute": _vm.isAdminView,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " Your message will be sent to the admin "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "pre-upload" },
                                  _vm._l(
                                    _vm.attachtments,
                                    function (attachtment, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "img-holder",
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "close-btn",
                                            attrs: { icon: "x-circle-fill" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeFile(index)
                                              },
                                            },
                                          }),
                                          !attachtment.type.includes("image") ||
                                          attachtment.type.includes("heic")
                                            ? _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "32",
                                                    height: "32",
                                                    viewBox: "0 0 24 24",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      fill: "currentColor",
                                                      d: "M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6zm7 6V3.5L18.5 9H14c-.55 0-1-.45-1-1z",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c("img", {
                                                attrs: {
                                                  src: attachtment.url,
                                                  alt: attachtment.name,
                                                },
                                              }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mr-0" },
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c("ComposerEditor", {
                                          ref: "composer",
                                          attrs: {
                                            "hide-toolbar": true,
                                            "hide-send-button": true,
                                          },
                                          model: {
                                            value: _vm.newMessage,
                                            callback: function ($$v) {
                                              _vm.newMessage = $$v
                                            },
                                            expression: "newMessage",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "px-0",
                                        attrs: { cols: "auto" },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: { variant: "link" },
                                            on: { click: _vm.openUpload },
                                          },
                                          [
                                            _c("IconPaperclip", {
                                              staticClass:
                                                "tw-w-5 tw-h-5 tw-text-gray-800",
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("input", {
                                          ref: "fileInput",
                                          staticStyle: { display: "none" },
                                          attrs: { type: "file", multiple: "" },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleFilesUpload()
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "px-0",
                                        attrs: { cols: "auto" },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: { variant: "link" },
                                            on: {
                                              click: function ($event) {
                                                _vm.showCannedReplyModal = true
                                              },
                                            },
                                          },
                                          [
                                            _c("IconCannedReply", {
                                              staticClass:
                                                "tw-w-5 tw-h-5 tw-text-gray-800",
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "px-0",
                                        attrs: { cols: "auto" },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "primary",
                                              pill: "",
                                              block: "",
                                              disabled: _vm.isMessageBodyEmpty,
                                            },
                                            on: {
                                              click: () => _vm.sendMessage(),
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "tw-text-xs" },
                                              [_vm._v(" Send Message ")]
                                            ),
                                          ]
                                        ),
                                        !_vm.isAdminView
                                          ? _c(
                                              "b-button",
                                              {
                                                staticStyle: {
                                                  "line-height": "0",
                                                },
                                                attrs: {
                                                  variant: "danger",
                                                  pill: "",
                                                  block: "",
                                                  disabled:
                                                    _vm.isMessageBodyEmpty,
                                                },
                                                on: {
                                                  click:
                                                    _vm.sendMessageNeedsReply,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "tw-text-xs" },
                                                  [_vm._v(" Send Message ")]
                                                ),
                                                _c("br"),
                                                _c(
                                                  "span",
                                                  { staticClass: "tw-text-xs" },
                                                  [_vm._v(" Needs Reply ")]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.conversationpatient.id == ""
                ? [
                    _c(
                      "b-card",
                      {
                        staticClass: "mb-0",
                        staticStyle: {
                          "background-color": "transparent",
                          "border-left": "0",
                          "border-right": "0",
                        },
                        attrs: { "no-body": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c("b-col", [
                                        _c("h3", { staticClass: "mb-0" }, [
                                          _vm._v("Conversation"),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          53309265
                        ),
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "chat-box",
                            staticStyle: {
                              "justify-content": "center",
                              "align-items": "center",
                              height: "calc(80vh - 232px)",
                            },
                          },
                          [
                            _vm.loading
                              ? _c("h3", { staticClass: "text-muted" }, [
                                  _vm._v(" Loading conversation details "),
                                ])
                              : _c("h3", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    " Select patient to view conversation "
                                  ),
                                ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm.conversationpatient.id != ""
            ? _c(
                "b-col",
                {
                  staticClass: "d-none d-sm-none d-lg-block",
                  attrs: { cols: "12", lg: "3" },
                },
                [
                  _vm.conversationpatient.id != ""
                    ? _c(
                        "b-card",
                        {
                          staticClass: "patient-info mb-0",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-row",
                                      { attrs: { "align-v": "center" } },
                                      [
                                        _c("b-col", [
                                          _c(
                                            "h3",
                                            { staticClass: "mb-0 text-black" },
                                            [_vm._v("Patient Information")]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "footer",
                                fn: function () {
                                  return [
                                    !_vm.currentDoctor.id &&
                                    _vm.conversationpatient.isBanned
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              pill: "",
                                              variant: "secondary",
                                              block: "",
                                              to: `/prescriptions/order/${_vm.conversationpatient.userId}`,
                                            },
                                          },
                                          [_vm._v(" Create a Plan ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          pill: "",
                                          variant: "outline-secondary",
                                          to: `/patients/profile/${_vm.conversationpatient.userId}`,
                                          block: "",
                                        },
                                      },
                                      [_vm._v(" View Patient Profile ")]
                                    ),
                                    !_vm.currentDoctor.id
                                      ? _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName:
                                                  "v-b-modal.viewDosespotFromMessage",
                                                modifiers: {
                                                  viewDosespotFromMessage: true,
                                                },
                                              },
                                            ],
                                            attrs: {
                                              pill: "",
                                              variant: "outline-secondary",
                                              block: "",
                                            },
                                          },
                                          [_vm._v(" Open Dosespot ")]
                                        )
                                      : _vm._e(),
                                    !_vm.currentDoctor.id
                                      ? [
                                          !_vm.conversationpatient
                                            .needsPatientReply
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass: "pb-0",
                                                  staticStyle: {
                                                    "box-shadow":
                                                      "none !important",
                                                  },
                                                  attrs: {
                                                    variant: "link",
                                                    block: "",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.onNeedPatientReply,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-secondary d-inline-block btn-patient-reply",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Mark Needs Reply "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("br"),
                                                ]
                                              )
                                            : _c(
                                                "b-button",
                                                {
                                                  staticClass: "pb-0",
                                                  staticStyle: {
                                                    "box-shadow":
                                                      "none !important",
                                                  },
                                                  attrs: {
                                                    variant: "link",
                                                    block: "",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.onRemoveNeedPatientReply,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-secondary d-inline-block btn-patient-reply",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Clear Needs Reply "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("br"),
                                                ]
                                              ),
                                        ]
                                      : _vm._e(),
                                    _vm.patientLastReplyDate &&
                                    !_vm.currentDoctor.id
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-black text-center patient-reply-description",
                                          },
                                          [
                                            _c("small", [
                                              _vm._v(
                                                " This patient hasn’t replied for "
                                              ),
                                              _c("strong", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.patientLastReplyDate
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            949995064
                          ),
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "mb-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          !_vm.conversationpatient.profileUrl
                                            ? _c("b-skeleton", {
                                                attrs: {
                                                  animation: "fade",
                                                  type: "avatar",
                                                  size: "85px",
                                                },
                                              })
                                            : _c("b-avatar", {
                                                attrs: {
                                                  size: "85px",
                                                  src: _vm.conversationpatient
                                                    .profileUrl,
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                      _c("div", {
                                        staticClass: "w-100 d-lg-none",
                                      }),
                                      _c("b-col", [
                                        _c(
                                          "h3",
                                          { staticClass: "bold text-black" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.conversationpatient
                                                    .firstName
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.conversationpatient
                                                    .lastName
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center justify-content-center",
                                                attrs: {
                                                  pill: "",
                                                  size: "sm",
                                                  variant: "outline-dark",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showNotesModal = true
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", {
                                                  attrs: {
                                                    name: "note",
                                                    variant: "",
                                                    size: "20",
                                                    color: "currentColor",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-inline-block ml-2",
                                                  },
                                                  [_vm._v(" Notes ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            !_vm.currentDoctor.id
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center justify-content-center",
                                                    attrs: {
                                                      pill: "",
                                                      size: "sm",
                                                      variant: "outline-dark",
                                                      block: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$bvModal.show(
                                                          "setReminder"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", {
                                                      attrs: {
                                                        name: "reminder",
                                                        color: "currentColor",
                                                        variant: "",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v("Set Reminder"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "text-muted mb-2" },
                                            [
                                              _c("small", [
                                                _vm._v("Date of Birth"),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "h4",
                                            { staticClass: "text-black" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.parseBirthdate(
                                                      _vm.conversationpatient
                                                        .birthday
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "text-muted mb-2" },
                                            [_c("small", [_vm._v("Age")])]
                                          ),
                                          _c(
                                            "h4",
                                            { staticClass: "text-black" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.conversationpatient.age
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "text-muted mb-2" },
                                            [_c("small", [_vm._v("State")])]
                                          ),
                                          _c(
                                            "h4",
                                            { staticClass: "text-black" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.conversationpatient.state
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "text-muted mb-2" },
                                            [_c("small", [_vm._v("Condition")])]
                                          ),
                                          _c(
                                            "h4",
                                            { staticClass: "text-black" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.conversationpatient
                                                    .symptoms
                                                )
                                              ),
                                            ]
                                          ),
                                          !_vm.conversationpatient.symptoms
                                            ? _c(
                                                "h4",
                                                { staticClass: "text-muted" },
                                                [_vm._v(" No records! ")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "text-muted mb-2" },
                                            [
                                              _c("small", [
                                                _vm._v("Other Medications"),
                                              ]),
                                            ]
                                          ),
                                          _vm.conversationpatient
                                            .otherMedication
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.conversationpatient
                                                    .otherMedication,
                                                  function (medication, i) {
                                                    return _c(
                                                      "h4",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "text-black",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(medication) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c(
                                                "h4",
                                                { staticClass: "text-black" },
                                                [_vm._v("No records!")]
                                              ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "h3",
                            {
                              staticClass: "mb-0 text-black",
                              staticStyle: { "font-size": "16px" },
                            },
                            [_vm._v(" Treatment Plans ")]
                          ),
                          _c(
                            "b-list-group",
                            { attrs: { flush: "" } },
                            [
                              _vm._l(
                                _vm.conversationpatient.treatmentPlanDetail,
                                function (treat, a) {
                                  return _c(
                                    "b-list-group-item",
                                    { key: a },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "mb-2",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "b-row",
                                                [
                                                  _c("b-col", [
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-black",
                                                        staticStyle: {
                                                          "font-size": "14px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            treat.description
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "auto" } },
                                                    [
                                                      _c("small", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.moment
                                                              .unix(treat.date)
                                                              .format(
                                                                "MM/DD/YY"
                                                              )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-row",
                                                [
                                                  _c(
                                                    "b-col",
                                                    [
                                                      _c("b-badge", {
                                                        class: [
                                                          "mr-2",
                                                          treat.status ==
                                                          "PAUSED"
                                                            ? "badge-warning"
                                                            : treat.status ==
                                                              "CANCELLED"
                                                            ? "badge-danger"
                                                            : treat.status ==
                                                              "ACCEPTED"
                                                            ? "badge-success"
                                                            : treat.status ==
                                                              "PRESCRIBED"
                                                            ? "badge-success"
                                                            : "",
                                                        ],
                                                        staticStyle: {
                                                          padding: "5px",
                                                          "border-radius":
                                                            "9999px",
                                                          display:
                                                            "inline-block",
                                                        },
                                                      }),
                                                      _c("small", [
                                                        _vm._v(
                                                          _vm._s(treat.status)
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm.conversationpatient.treatmentPlanDetail !=
                                undefined &&
                              _vm.conversationpatient.treatmentPlanDetail
                                .length == 0
                                ? _c("b-list-group-item", [
                                    _c(
                                      "p",
                                      { staticClass: "text-muted mb-0" },
                                      [_vm._v("No Medication")]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm.conversationpatient.patientNotes
                            ? _c(
                                "b-row",
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        { attrs: { "align-v": "center" } },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-muted mb-2",
                                                },
                                                [
                                                  _c("small", [
                                                    _vm._v("Patient Notes"),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "h4",
                                                { staticClass: "text-black" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.conversationpatient
                                                          .patientNotes
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "viewDosespotFromMessage",
            centered: "",
            "hide-footer": "",
            size: "xl",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ close }) {
                return [
                  _c("b-container", { attrs: { fluid: "" } }),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "link" },
                      on: { click: close },
                    },
                    [
                      _c("b-icon", {
                        staticStyle: { width: "30px", height: "30px" },
                        attrs: { icon: "x-circle-fill", variant: "secondary" },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.isDosespotModalOpen,
            callback: function ($$v) {
              _vm.isDosespotModalOpen = $$v
            },
            expression: "isDosespotModalOpen",
          },
        },
        [
          _c(
            "b-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _vm.isFetchingDosespot && !this.dosespotURL
                    ? [
                        _c(
                          "b-row",
                          {
                            staticStyle: { height: "350px" },
                            attrs: { "align-v": "center" },
                          },
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "12" },
                              },
                              [
                                _c("h1", { staticClass: "text-primary" }, [
                                  _vm._v("Loading Dosespot..."),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _c("iframe", {
                        style: { minHeight: _vm.iframeHeight + "px" },
                        attrs: {
                          src: this.dosespotURL,
                          width: "100%",
                          height: "100%",
                          frameborder: "0",
                          scrolling: "yes",
                          allowtransparency: "true",
                        },
                      }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-set-reminder", {
        attrs: {
          patient: {
            ..._vm.conversationpatient,
            id: _vm.conversationpatient.userId,
          },
        },
      }),
      _c("modal-forward-messages", {
        attrs: {
          "selected-messages": _vm.selectedMessages,
          conversationmemberlist: _vm.conversationmemberlist,
          conversationid: _vm.conversationId,
        },
        on: { success: _vm.onForwardMessageSuccess },
      }),
      _c("modal-view-forward-messages", {
        ref: "viewForwardMessages",
        attrs: {
          conversationmemberlist: _vm.conversationmemberlist,
          conversationid: _vm.conversationId,
        },
      }),
      _c(
        "portal",
        { attrs: { to: "notes" } },
        [
          _c("ModalBodyNotes", {
            attrs: {
              "show-notes-modal": _vm.showNotesModal,
              "patient-id": _vm.conversationpatient.userId,
            },
            on: {
              "update:showNotesModal": function ($event) {
                _vm.showNotesModal = $event
              },
              "update:show-notes-modal": function ($event) {
                _vm.showNotesModal = $event
              },
            },
          }),
        ],
        1
      ),
      _c("SideModal", {
        key: _vm.cannedReplyModalKey,
        attrs: {
          show: _vm.showCannedReplyModal,
          "header-class": "canned-reply-header",
          "body-class": "canned-reply-body",
          title: "Canned Replies",
          side: "left",
        },
        on: {
          exit: function ($event) {
            _vm.showCannedReplyModal = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("CannedReplyModal", {
                  attrs: {
                    "patient-name": _vm.conversationpatient.firstName,
                    "is-visible": _vm.showCannedReplyModal,
                  },
                  on: {
                    "close-canned": function ($event) {
                      _vm.showCannedReplyModal = false
                    },
                    "add-attachment": _vm.addAttachment,
                    "add-message": _vm.addMessage,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend tw-px-6" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "fe fe-search text-primary" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }